import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PanelSample from "../components/panel-sample"
import PanelBeans from "../components/panel-beans"
import Header from "../components/header"

const IndexPage = (  ) => (

  <Layout>
    
    <SEO 
      title="Buy our beans"
      description="Buy our single origin Colombian coffee beans in bulk" />

    <Header />

    <PanelBeans />

    <PanelSample />

  </Layout>
)

export default IndexPage
